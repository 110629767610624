import {defineStore} from "pinia";
import axios from "axios";
import { setProject } from "@/composables/ModuleCommunication";

export const useProjects = defineStore({
    id: 'projectsStore',
    state: () => ({
        projects: [],
        activeProject: '',
        countries: [],
        foundedCountryId: ''
    }),
    actions: {

        async getProject(id){
            let result = await axios.get(`/globus/api/Projects/${id}`)
             return result.data
        },
        async getProjects(){
            await axios.get(`/globus/api/Projects`).then((response) => {
                this.projects = response.data
            }).catch((error) => {
                console.log(error)
            })
        },
        async createProject(data){
            await axios.post(`/globus/api/Projects`, data).then(async (res) => {
                console.log(res.data)
                this.projects.push(res.data)
                await this.getProjects()
            }).catch((error) => {
                console.log(error)
            })

        },
        async updateProject(data){
            await axios.patch(`/globus/api/Projects/${data.id}`, data).then(() => {
                this.getProjects()
            }).catch((error) => {
                console.log(error)
            })
        },
        async deleteProject(id){
            await axios.delete(`/globus/api/Projects/${id}`).then(() => {
                this.projects = this.projects.filter(item => item.id !== id)
            }).catch((error) => {
                console.log(error)
            })
        },

        async setActiveProject(id) {
          await axios.patch(`/globus/api/User/profile`, {activeProject: id}).then(async () => {

              this.activeProject = this.projects.find(project => project.id === id)
          }).catch((error) => {
              console.log(error)
          })
        },

        clearData() {
            this.projects = []
            this.activeProject = ''
            this.countrys = ''
            setProject('') // очистка юзера в приложение 
        },

        async getCountry(iso2) {
            await axios.get(`/globus/api/Country/${iso2}`).then((response) => {
                this.foundedCountryId = response.data
                return response.data
            }).catch((error) => {
                console.log(error)
                return false
            })
        },

        async getCountries(){
            await axios.get(`/globus/api/Country`).then((response) => {
                this.countries = response.data
            }).catch((error) => {
                console.log(error)
            })
        },
    }
});
