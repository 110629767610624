<script setup>

import { defineModel } from 'vue'

const model = defineModel()



</script>

<template>
  <input
    v-model="model"
    type="text"
    placeholder="Поиск по проектам"
  >
  <svg
    class="search-icon"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_611_1860)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 6.41679C2.75 4.39174 4.39162 2.75012 6.41667 2.75012C8.44171 2.75012 10.0833 4.39174 10.0833 6.41679C10.0833 7.39521 9.70011 8.28412 9.07557 8.94161C9.05143 8.9616 9.028 8.9829 9.00539 9.00552C8.98278 9.02813 8.96148 9.05155 8.94149 9.07569C8.284 9.70023 7.39508 10.0835 6.41667 10.0835C4.39162 10.0835 2.75 8.44183 2.75 6.41679ZM9.65397 11.0683C8.73626 11.7082 7.62028 12.0835 6.41667 12.0835C3.28705 12.0835 0.75 9.5464 0.75 6.41679C0.75 3.28717 3.28705 0.750122 6.41667 0.750122C9.54628 0.750122 12.0833 3.28717 12.0833 6.41679C12.0833 7.62041 11.7081 8.73638 11.0682 9.65409L12.9571 11.543C13.3476 11.9335 13.3476 12.5667 12.9571 12.9572C12.5666 13.3478 11.9334 13.3478 11.5429 12.9572L9.65397 11.0683Z"
        fill="#CACACA"
      />
    </g>
    <defs>
      <clipPath id="clip0_611_1860">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

input {
  width: 14vw;
  height: 36px;
  border-radius: 8px 0 0 8px;
  padding: 10px;
  gap: 10px;
  border-top: 1px solid $grey-light;
  border-left: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
}

.search-icon {
  height: 36px;
  width: 26px;
  padding-right: 10px;
  border-radius: 0 8px 8px 0;

  border-top: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
}
</style>