import { defineStore } from "pinia";

export const useStore = defineStore({
    id: 'store',
    state: () => ({
        loader: false,
        notifications: [],
        showType: 'login', // login close open
        activeModule: { name: 'Глобус', dataName: 'module_globus', img: '' },
        confirmExitModalIsActive: false,
        routing: [],
        mainHrefApp: '',
        showMode: 'settings',

    }),
    actions: {
        createNotify(head, mess, type) {
            console.log(head, mess, type)
            this.notifications.push({
                header: head,
                message: mess,
                color: type === 'succ' ? 'green' : 'red',
                lifeTime: type === 'succ' ? 5000 : 5000
            })
            console.log(this.notifications)
        },
    }
});
