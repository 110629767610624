<template>
  <div class="header">
    <ProjectHeader />
    <div
      v-if="openParam || openNav || openLangs || popupProjectsOn"
      class="header-background"
      @click="openParam = false; openNav = false; openLangs = false; popupProjectsOn = false"
    />
    <div class="centried header-menu">
      <div
        class="header-project-name"
        :class="{active: popupProjectsOn}"
        @click="togglePopupVisible"
      >
        <span v-if="projectStore.activeProject">
          {{ 'Проект: ' + projectStore.activeProject.name }}
        </span>
        <span v-else>Выберите проект</span>
        <div
          v-if="popupProjectsOn"
          class="header-project-name__popup"
        >
          <ProjectsBlock
            :key="KEY"
            @refresh="KEY++"
          />
          <button @click="openProjects">
            Все проекты
          </button>
        </div>
      </div>

      <div
        class="header-user-profile profile "
        @click="openParam = !openParam"
      >
        <img class="user-image" v-if="userStore.user.image" :src="userStore.user.image" alt="">
        <svg-icon v-else class="user-image" name="undefined-photo"/>
        <div
          v-if="openParam"
          class="header-dropdown"
        >
          <div
            v-if="router.currentRoute.fullPath === '/lk' && projectStore.activeProject.id"
            class="panel-item header-dropdown__item"
            @click="goMainPage()"
          >
            <svg-icon width="15px" height="15px" name="home"/>

            <p class="button-text">
              Вернуться в приложение
            </p>
          </div>
          <div
            class="panel-item header-dropdown__item"
            to="lk"
            @click="openPersonalArea()"
          >
            <svg-icon width="15px" height="15px" name="user"/>

            <p class="button-text">
              {{ mainStorage.getItem('username') }}
            </p>
          </div>
          <div
            class="panel-item header-dropdown__item"
            @click="exit()"
          >
            <SvgIcon width="15px" height="15px" name="exit"/>

            <p class="button-text">
              Выйти
            </p>
          </div>
        </div>
      </div>
      <div
        class="centried header-langs"
      >
        <div
          class="header-langs-title"
          @click="openLangs = true"
        >
          {{ currentLang }}
        </div>
        <div
          v-if="openLangs"
          class="header-dropdown _langs"
        >
          <div
            v-for="(lang, index) of langs"
            :key="index"
            class="lang-item"
            :class="{ active: lang === currentLang }"
            @click="changeLang(lang); openLangs = false"
          >
            {{ lang }}
          </div>
        </div>
      </div>
      <div
        class="centried header-user-profile" :class="{'burger-menu_selected': openNav}"
      >
        <SvgButton
          v-if="userStore.user?.modules?.length > 1"
          name="burger-header"
          :height="'45px'"
          :width="'45px'"
          @action="openNav = true"
        />
        <div
          v-if="openNav"
        >
          <ServicesBlock />
        </div>
      </div>
    </div>
    <ConfirmExit />
  </div>
</template>

<script>
import {useProjects} from '@/store/projects';
import ProjectHeader from '@/components/common/ProjectHeader.vue';
import {confirmExit} from '@/composables/functionExit';
import {goMainPage, goLkPage} from '@/composables/ModuleCommunication'
import {useStore} from "@/store/index.js"
import SvgButton from './common/svgButton.vue';
import ServicesBlock from '@/components/common/header/ServicesBlock.vue'
import ProjectsBlock from '@/components/common/header/ProjectsBlock.vue'
import ConfirmExit from '@/components/GlobusHeader/ConfirmExit.vue';


import router from '@/router';
import {useUser} from "@/store/user";
import {mainStorage} from "@/composables/tools/main-storage.tools";
import SvgIcon from "@/components/common/svgButton.vue";

export default {
  components: {
    SvgIcon,
    ServicesBlock,
    ProjectsBlock,
    ProjectHeader,
    SvgButton,
    ConfirmExit
  },
  data() {
    return {
      KEY: 1,
      openNav: false,
      openLangs: false,
      openParam: false,
      Store: useStore(),
      projectStore: useProjects(),
      langs: ['RU', 'EN', 'FR', 'ES', 'AR'],
      currentLang: 'RU',
      router: router,
      popupProjectsOn: false,
      userStore: useUser(),
      mainStorage: mainStorage
    }
  },
  methods: {
    openProjects() {
      goLkPage()
      this.Store.showMode = "projects"
    },
    openPersonalArea() {
      goLkPage()
    },
    exit() {
      confirmExit()
    },
    togglePopupVisible() {
      this.popupProjectsOn = !this.popupProjectsOn;
    },
    changeLang(lang) {
      console.log(lang)
      // this.currentLang = lang
    },
    goMainPage() {
      goMainPage()
    },

  }
}
</script>

<style
    lang="scss"
    scoped
>
@import '@/style/styles.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 10px;
  box-sizing: border-box;
}

.header-menu {
  display: flex;
  gap: 10px;
}

.header-project-name {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  height: 30px;
  min-width: 120px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #fff;
  border-radius: 22px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.active {
    background: #1A64D433;
  }

  &__popup {
    position: absolute;
    top: 5vh;
    right: 0;
    z-index: 9999;
    min-height: 150px;
    height: 150px;
    padding: 6px;
    background: #fff;
    box-shadow: 1px 2px 10px 0 #131E2726;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 4px;
    width: 255px;

    button {
      background: #1A64D4;
      height: 38px;
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: none;
      min-height: 38px;
      cursor: pointer;
    }
  }
}

.header-background {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}



.header-user-profile {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-dropdown {
  box-sizing: border-box;
  padding: 6px;
  position: absolute;
  min-width: 138px;
  min-height: 76px;
  z-index: 2000;
  top: 6vh;
  right: 0;
  box-shadow: $box-shadow;
  background-color: white;
  border-radius: 8px;

  &._langs {
    width: 100%;
    min-width: 6vh;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
    border-radius: 8px;
    box-shadow: 2px 4px 20px 0px rgba(0, 32, 57, 0.15);
    top: 6vh;
    padding: 1vh;
  }
}

.header-dropdown-option {
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap;
  padding: 1vh;

  &:hover {
    background-color: $status-active-hover;
  }
}

.header-langs {
  position: relative;
}

.header-langs-title {
  border-radius: 50%;
  height: 44px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  box-sizing: border-box;
  font-family: sans-serif;
  color: black;
}

.lang-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vh;
  width: 4vh;
  transition: .2s;
  border: 1px solid transparent;
  border-radius: 0.8vh;
  cursor: pointer;
  font-size: 1.6vh;

  &.active {
    color: $globe-active-color;
    border-color: $globe-active-color;
    cursor: default;
  }

  &:hover {
    &:not(.active) {
      background-color: #F4FAFF;
    }
  }

  &:active {
    &:not(.active) {
      background-color: #F4FAFF;
    }
  }
}

.profile {
  width: 45px;
  height: 45px;
  background: #FFFFFF;
  border-radius: 50%;
}

.header-dropdown__item {
  display: flex;
  gap: 5px;
  margin: 0;
  text-decoration: none;
}

.user-image {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.burger-menu_selected {
  background: #FFFFFF;
  border-radius: 50%;
}
</style>