<script setup>
import SearchInput from "@/components/LKPage/Input/SearchInput.vue";
import {onMounted, ref, defineProps, computed} from "vue";
import {useProjects} from "@/store/projects";
import SvgIcon from "@/components/common/svgButton.vue";
import ProjectModal from "@/components/LKPage/LKWindow/ProjectWindow/ProjectModal.vue";
import BlockView from "@/components/common/BlockView.vue";
import DeleteProjectModal from "@/components/LKPage/LKWindow/ProjectWindow/DeleteProjectModal.vue";
import {useUser} from "@/store/user";


onMounted(async () => {
  await projectStore.getProjects().then(() => {
    updateProjectList()
  })
})

const updateProjectList = () => {
  if (props.isArchive) {
    projectList.value = projectStore.projects.filter((project) => project.isArchive === true)
  } else {
    projectList.value = projectStore.projects.filter((project) => project.isArchive === false)
  }
}

const projectSearchString = ref('')

/* Пагинатор **/

const activePage = ref(1)

const rowPerPage = computed(() => {
  if (props.isArchive) {
    return 20
  } else {
    return 19
  }
})

const pages = computed(() => {
  const items = projectList.value.length
  let pagesCount = 1

  if (items % rowPerPage.value === 0 ) {
    pagesCount = items / rowPerPage.value
  } else {
    pagesCount = items / rowPerPage.value
    pagesCount = parseInt(pagesCount) + 1
  }

  return pagesCount
})

const getPageItems = computed(() => {

  if (projectSearchString.value.length > 0) {
    return projectList.value.filter((project) => { return project.name.includes(projectSearchString.value) })
  } else {
    return projectList.value.slice((activePage.value - 1) * rowPerPage.value, activePage.value * rowPerPage.value);
  }
})

const prevPage = () => {
  if (activePage.value !== 1) {
    activePage.value -= 1;
  }
}

const nextPage = () => {
  if (activePage.value !== pages.value) {
    activePage.value += 1;
  }
}

const props = defineProps({
  isArchive: {
    type: Boolean,
    default: false
  }
})

const projectStore = useProjects()
const userStore = useUser()

const projectList = ref([])

const isEdit = ref(false)

const createProjectModalShow = ref(false)
const toggleCreateProjectModal = () => {
  isEdit.value = false
  createProjectModalShow.value = !createProjectModalShow.value
}

const editProjectModalShow = ref(false)
const toggleEditProjectModal = () => {
  isEdit.value = true
  createProjectModalShow.value = !createProjectModalShow.value
}

const deleteProjectModalShow = ref(false)
const toggleDeleteProjectModal = () => {
  deleteProjectModalShow.value = !deleteProjectModalShow.value
}

const selectedProject = ref(null)
const showProjectMenu = (project) => {
  selectedProject.value = project
}

/* Перенос проекта в архив **/
const moveToArchive = async () => {
  selectedProject.value.isArchive = true
  await projectStore.updateProject(selectedProject.value).then(() => {
    projectList.value = projectList.value.filter((item) => item !== selectedProject.value)
    selectedProject.value = null
  })
}

/* Перенос проекта из архива **/
const moveFromArchive = async () => {
  selectedProject.value.isArchive = false
  await projectStore.updateProject(selectedProject.value).then(() => {
    projectList.value = projectList.value.filter((item) => item !== selectedProject.value)
    selectedProject.value = null
  })
}

/* Добавление суффикса **/
function addOrdinal(n) {
  const s = ["ков", "к", "ка", "ка"]
  let v = n % 100

  return (s[(v - 20) % 10] || s[v] || s[0])
}

/* Сделать выбранный проект активным **/

const setActiveProject = () => {
  projectStore.setActiveProject(selectedProject.value.id)
  userStore.user.activeProject = selectedProject.value.id
  selectedProject.value = null
}

</script>

<template>
  <div class="window-wrapper">
    <div class="header">
      <div class="header__title">
        Список проектов
      </div>
      <div class="header__search">
        <SearchInput
          v-model="projectSearchString"
        />
      </div>
    </div>

    <div class="body">
      <div
        v-if="isArchive === false"
        class="new-project"
        @click="createProjectModalShow = true"
      >
        <div class="new-project__title">
          <SvgIcon
            width="42px"
            height="42px"
            :name="'plus'"
          />
        </div>
        <div
          class="new-project__title"
        >
          Новый проект
        </div>
      </div>

      <div
        v-for="project in getPageItems"
        :key="project"
        class="project"
        :class="{'project-active': project.id === userStore.user.activeProject}"
      >
        <div class="project__icon">
          <SvgIcon
            width="29px"
            height="29px"
            :name="'module_globus'"
          />
        </div>
        <div class="project-body">
          <div class="project-body__title">
            Проект {{ project.name }}
          </div>
          <div
            class="project-body__button"
            @click="showProjectMenu(project)"
          >
            <SvgIcon
              width="14px"
              height="14px"
              :name="`burger-mini`"
            />
          </div>
          <div
            v-if="selectedProject === project && deleteProjectModalShow === false
              && editProjectModalShow === false
              && createProjectModalShow === false"
            class="project-body-menu"
          >
            <div
              class="project-body-menu__item"
              @click="setActiveProject"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`access-control`"
              />
              Сделать активным
            </div>

            <div
              class="project-body-menu__item"
              @click="toggleEditProjectModal"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`edit`"
              />
              Настройки проекта
            </div>

            <div
              v-if="isArchive === false"
              class="project-body-menu__item"
              @click="moveToArchive()"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`archive`"
              />
              Добавить в архив
            </div>

            <div
              v-else
              class="project-body-menu__item"
              @click="moveFromArchive()"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`archive`"
              />
              Перенести в активные
            </div>

            <div
              class="project-body-menu__item"
              @click="deleteProjectModalShow = true"
            >
              <SvgIcon
                width="14px"
                height="14px"
                :name="`delete`"
              />
              Удалить
            </div>
          </div>
        </div>
        <div class="project__member">
          {{ project.users.length }} участни{{ addOrdinal(project.users.length) }}
        </div>
      </div>
    </div>

    <div class="footer">
      <div v-if="pages > 1" class="paginator">
        <div
          class="paginator__prev paginator__item"
          @click="prevPage"
        >
          &lt;
        </div>
        <div
          v-for="page in pages"
          :key="page"
          class="paginator__item"
          :class="{'paginator__item-active': page === activePage}"
          @click="activePage = page"
        >
          {{ page }}
        </div>
        <div
          class="paginator__next paginator__item"
          @click="nextPage"
        >
          >
        </div>
      </div>
    </div>
  </div>

  <ProjectModal
    :show="createProjectModalShow"
    :is-edit="isEdit"
    :project="selectedProject"
    @close-modal="toggleCreateProjectModal"
    @update-project-list="updateProjectList()"
  />

  <DeleteProjectModal
    :project-id="selectedProject?.id"
    :project-name="selectedProject?.name"
    :show="deleteProjectModalShow"
    @close-modal="toggleDeleteProjectModal"
  />


  <BlockView
    v-if="selectedProject"
    @click="selectedProject=null; deleteProjectModalShow = false"
  />
</template>

<style scoped lang="scss">

@import '@/assets/styles/vars';

.window-wrapper {
  display: flex;
  flex-direction: column;

  gap: 33px;
  .header {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    &__title {
      font-size: 28px;
      font-weight: 400;
    }

    &__search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;


    .project {
      display: flex;
      flex-direction: column;
      gap: 8px;
      cursor: pointer;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;

      height: 107px;
      width: 19.2vw;

      border: 1px solid $globe-passive-color;
      border-radius: 8px;

      &:hover {
        border: none;
        background: $hover-active-color;
      }

      &-active {
        border-color: $globe-active-color;
      }

      &-body {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__title {
          display: inline-block;

          text-align: start;

          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 12vw;
          font-size: 14px;
          font-weight: 500;
          color: $black;
        }

        &__button {
          width: 14px;
          height: 14px;
        }

        &-menu {
          position: absolute;
          top: 0;
          left: 95px;
          width: 210px;
          min-height: 120px;
          background: #FFFFFF;
          box-shadow: 1px 2px 10px 0 #131E2726;
          border-radius: 8px;
          z-index: 2000;
          padding: 6px;

          &__item {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            padding-left: 8px;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            border-radius: 8px;
            gap: 4px;

            &:hover {
              background: $hover-active-color;
            }
          }
        }
      }
    }

    .new-project {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      justify-content: center;
      align-items: center;

      height: 107px;
      width: 19.2vw;

      background: $blue;
      border-radius: 8px;
      color: #FFFFFF;

      &__title {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .footer {
    position: relative;
    right: 30px;
    display: flex;
    justify-content: flex-end;

    .paginator {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 24px;
        height: 24px;

        &-active {
          border: 1px solid $blue;
          border-radius: 4px;
        }
      }
    }
  }
}

</style>