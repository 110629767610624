import {mainStorage} from "@/composables/tools/main-storage.tools.js";

/**
 * Инициализирует интерсепторы для экземпляра Axios, добавляя токен авторизации к каждому запросу
 * и обрабатывая ошибки ответов.
 *
 * @param {Object} axios - Экземпляр Axios, к которому будут добавлены интерсепторы.
 * @param {Object} LKStore - Экземпляр хранилища (LKStore), который используется для управления состоянием
 *                           и перенаправления пользователя на страницу логина в случае ошибок авторизации.
 */
export function axiosInit(axios, LKStore) {
    axios.interceptors.request.use(async (config) => {
        if (!config.url.includes("/login")) {
            const token = mainStorage.getItem("access_token");
            config.headers.Authorization = "Bearer " + token;
        }
        return config;
    });
    axios.interceptors.response.use(null, (error) => {
        const status = error?.response?.status;
        if (status === 401 || status === 403 && false) {
            console.log('Ошибка аутентификации на сервере.', error)
            console.log(LKStore)
            LKStore.goLogin();
        } else {
            console.log('Возникла ошибка при загрузке данных:', error)
        }
        return error;
    });
}
