<template>
  <ProjectHeader v-if="router.currentRoute.fullPath !== 'login'" />
  <RouterView />
  <NotificationBlock />
  <GlobalLoader v-if="STORE.loader" />
</template>

<script>
import GlobalLoader from '@/components/common/GlobalLoader.vue';
import ProjectHeader from '@/components/GlobusHeader.vue';
import NotificationBlock from '@/components/notifications/NotificationBlock.vue';
import { getToken } from '@/composables/auth';
import {useStore} from '@/store'
import {changeShow, changeLoadApp, setProject} from '@/composables/ModuleCommunication'
import {modules} from '@/composables/constans/modules'
import {mainStorage} from '@/composables/tools/main-storage.tools'
import { useProjects } from './store/projects';
import { useUser } from '@/store/user';
import { exit } from '@/composables/functionExit';
import router from '@/router/index'

export default {
  components: {
    GlobalLoader,
    NotificationBlock,
    ProjectHeader,
  },
  data(){
    return {
      STORE: useStore(),
      storeProjects: useProjects(),
      userStore: useUser(),
      mainStorage: mainStorage,
      router: router
    }
  },
  async created(){
    this.STORE.loader = true
    this.setListener()
  },
  methods: {
    setListener(){
      window.addEventListener("SetModule", async (event) => {
        const module = modules.find(item => item.dataName === event.detail.module) 
        if(module){
          this.STORE.activeModule = module
        } else {
          this.STORE.activeModule = {name: 'Личный кабинет', dataName: 'module_lk', img: ''}
        }
        this.STORE.routing = event.detail.routing
        this.STORE.mainHrefApp = event.detail.path
        await this.init()
      })
      window.addEventListener("GoToLogin", () => {
        exit()
        changeShow(false)
      })
      window.addEventListener("getToken", async () => {
        const token = await getToken()
        if(token){
          return token
        } else {
          exit()
          changeShow(false)
        }
      })
    },
    async init() {
      try{
        const token = await getToken()
        if(token){
          await this.userStore.getUser()
          console.log(this.userStore.user)
          if(!this.userStore.user.modules.find(item => item.moduleId === this.STORE.activeModule.dataName)){
            exit()
            this.STORE.createNotify('Нет доступа к модулю',
                'У данного пользователя нет доступа к модулю, обратитесь к администратору', '')
            this.STORE.loader = false
            return
          }
          if(this.userStore.user.activeProject){
            await this.storeProjects.getProjects()
            let project = await this.storeProjects.getProject(this.userStore.user.activeProject)

            if(project){
              this.storeProjects.activeProject = project
              setProject(project)
              changeLoadApp(true)
              changeShow(true)
            }
          } else {
            changeLoadApp(false)
            changeShow(false)
          }
        }
      } catch(e){
        console.log(e)
      }
      this.STORE.loader = false
    }
  },
}

</script>
<style lang="scss">

#appMain {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app {
  font-family: Roboto;
}

*, *::before, *::after {
  box-sizing: border-box;
}
p {
  padding: 0;
  margin: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

ul,li {
  list-style: none;
  padding: 0;
  margin: 0;
}

</style>
