<template>
    <teleport to="#modal" v-if="Store.confirmExitModalIsActive">
        <ModalCont 
            @close="Store.confirmExitModalIsActive = false"
            width="300px"
        >
            <div class="text">
                Вы уверены?
            </div>
            <GlobusButtonPrimary 
                @click="exit()" 
                :text="'Выйти из аккаунта'" 
                class="btn"
            >
            </GlobusButtonPrimary>
        </ModalCont>
    </teleport>

</template>

<script setup>
import { useStore } from '@/store';
import ModalCont from '@/components/common/ModalCont.vue'
import GlobusButtonPrimary from '@/components/common/GlobusButtonPrimary.vue';
import { exit } from '@/composables/functionExit';
const Store = useStore()

</script>

<style lang="scss" scoped>
.text {
    color: #1F9AFF;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 70px;
    margin-top: 17px;
    text-align: center;
}

.btn {
    margin: 0 auto 25px;
}
</style>