<template>
  <button class="routing-item"
          :class="{active: isActive}"
          @click="routerClick()"
  >
    <SvgButton :name="link.icon" :hover="isActive" color="#7B87A5" hoverColor="#1F9AFF"/>
    <div class="clue">
      {{ link.text_ru }}
    </div>
  </button>
</template>

<script setup>
import router from '@/router/index'
import SvgButton from '@/components/common/svgButton.vue';
import { computed, defineProps } from 'vue';
import {useStore} from "@/store";

const props = defineProps({
  link: {
    type: Object,
    required: true,
  }
})

const mainStore = useStore()

const routerClick = () => {
  if (props.link.disabled) {
    mainStore.createNotify('Уведомление', 'Данная страница отключена.', 'error')
  } else if (router.currentRoute.value.fullPath !== props.link.route) {
    router.push(props.link.route)
  }
}

const isActive = computed(() => {
  if (props.link.active) {
    return router.currentRoute.value.fullPath.includes(props.link.active)
  } else {
    return router.currentRoute.value.fullPath === props.link.route
  }
})

</script>

<style lang="scss" scoped>
.routing-item {
  border-radius: 50%;
  transition: .2s;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;
  position: relative;

  &:hover {
    & .clue {
      opacity: 1;
    }
  }

  &.active {
    background: #F7F8FD;
  }

  & svg {
    width: 21px;
    height: 20px;
  }
}

.clue {
  position: absolute;
  left: 50%;
  top: calc(100% - 4px);
  background: #fff;
  font-size: 14px;
  padding: 6px 14px;
  border-radius: 8px;
  opacity: 0;
  transition: .2s;
  transform: rotate(-50%, 0);
  z-index: 1;
}
</style>