<template>
  <div
    v-if="projectStore.projects.length > 0"
    class="panel-box"
  >
    <div class="panel__list scroll">
      <div
        v-if="projectStore.activeProject"
        class="panel__list-item current"
      >
        <p>{{ projectStore.activeProject.name }} <span>Вы тут</span></p>
      </div>
      <div
        v-for="(item, i) in projectList"
        :key="i"
        class="panel__list-item"
        @click="setActiveProject(item)"
      >
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>

import {useProjects} from "@/store/projects";
import {onMounted, ref} from "vue";
import {setProject} from "@/composables/ModuleCommunication";

onMounted(async () => {
  if (!projectStore.projects.length || projectStore.projects.length === 0) {
    await projectStore.getProjects()
  }
})

const projectStore = useProjects()

const projectList = ref(
    projectStore.projects.filter((project) => {
      return project.id !== projectStore.activeProject.id
    })
)

const setActiveProject = (project) => {
  projectStore.setActiveProject(project.id)
  setProject(project)
}

</script>

<style
    lang="scss"
    scoped
>
@import '@/style/styles.scss';

.panel-box {
    height: 100%;
}

.panel__list {
    overflow-y: scroll;
    height: 96px;

    &-item {
        height: 32px;
        border-radius: 8px;
        padding: 6px 14px 6px 14px;
        font-size: 14px;
        line-height: 20px;

        &:hover {
            background-color: #F7F8FD;
        }

        &.current {
            p {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }

        span {
            font-size: 14px;
            color: #A7A7A7;
            line-height: 20px;
        }

        & p {
            max-width: 21vh;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>