<script setup>
import {defineEmits, defineProps} from "vue";
import {useProjects} from "@/store/projects";

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  projectName: {
    type: String,
    default: ''
  },
  projectId: {
    type: Number,
    default: null
  }
})

const emit = defineEmits(['closeModal'])

const projectStore = useProjects()

const deleteProject = () => {
  projectStore.deleteProject(props.projectId)
  emit('closeModal')
}
</script>

<template>
  <div
    v-if="props.show"
    class="modal-wrapper"
  >
    <div class="modal-delete-project">
      <div class="modal-delete-project-header">
        Подвердите действие
      </div>
      <div class="modal-delete-project-body">
        Вы действительно хотите удалить проект: {{ projectName }} ?
      </div>
      <div class="modal-delete-project-footer">
        <div
          class="modal-delete-project-footer__button"
          @click="emit('closeModal')"
        >
          Закрыть
        </div>
        <div
          class="modal-delete-project-footer__button"
          @click="deleteProject()"
        >
          Удалить
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 300px;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
  z-index: 2000;

  .modal-delete-project {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500
    }

    &-body {
      line-height: 20px;
    }

    &-footer {
      display: flex;
      justify-content: center;
      gap: 10px;
      &__button {
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        cursor: pointer;
        height: 40px;
        background-color: $blue;
      }
    }
  }

}
</style>