<script setup>
import SvgIcon from "@/components/common/svgButton.vue";
import {onMounted, ref} from "vue";
import {useUser} from "@/store/user";
import UserAvatar from "@/components/LKPage/LKWindow/ProfileWindow/UserAvatar.vue";
import TextInput from "@/components/LKPage/LKWindow/ProfileWindow/Input/TextInput.vue";


const userStore = useUser()
const isEdit = ref(false)
const errorList = ref([])

const toggleEdit = () => {
  isEdit.value = !isEdit.value;
  userFormData.value = JSON.parse(JSON.stringify(userStore.user));
}

onMounted(async () => {
  await userStore.getUser().then(() => {
    userFormData.value = JSON.parse(JSON.stringify(userStore.user));
  })
})

const userFormData = ref({
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  image: '',
  email: ''
})

const saveUser = async () => {
  errorList.value = []

  if (userFormData.value.firstName !== null && userFormData.value.firstName.length <= 0) {
    errorList.value.push({
      type: 'firstName',
      message: 'Поле обязательно для заполнения'
    })
  } else if (validateName(userFormData.value.firstName)) {
    errorList.value.push({
      type: 'firstName',
      message: 'Разрешено использовать только кириллицу'
    })
  }

  if (userFormData.value.lastName !== null && userFormData.value.lastName.length <= 0) {
    errorList.value.push({
      type: 'lastName',
      message: 'Поле обязательно для заполнения'
    })
  } else if (validateName(userFormData.value.lastName)) {
    errorList.value.push({
      type: 'lastName',
      message: 'Разрешено использовать только кириллицу'
    })
  }

  if (userFormData.value.middleName !== null && userFormData.value.middleName.length <= 0) {
    errorList.value.push({
      type: 'middleName',
      message: 'Поле обязательно для заполнения'
    })
  } else if (validateName(userFormData.value.middleName)) {
    errorList.value.push({
      type: 'middleName',
      message: 'Разрешено использовать только кириллицу'
    })
  }

  if (userFormData.value.email !== null && userFormData.value.email.length <= 0) {
    errorList.value.push({
      type: 'email',
      message: 'Поле обязательно для заполнения'
    })
  } else if (!validateEmail(userFormData.value.email)) {
    errorList.value.push({
      type: 'email',
      message: 'Неверный формат e-mail адреса. Прим. user@globus.ru'
    })
  }

  if (userFormData.value.phone !== null && userFormData.value.phone.length <= 0) {
    errorList.value.push({
      type: 'phone',
      message: 'Поле обязательно для заполнения'
    })
  } else if (!validatePhone(userFormData.value.phone)) {
    errorList.value.push({
      type: 'phone',
      message: 'Неверный формат номера телефона. Прим. +7 (800) 555-35-35'
    })
  }

  if (errorList.value.length === 0) {
    await userStore.updateUser(userFormData.value).then(() => {
      toggleEdit()
    })
  }
}

const validatePhone = (phone) => {
  const re = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm
  return re.test(phone)
}

const validateEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  return re.test(email)
}

const validateName = (name) => {
  const re = /[\d\w\s(!@#$%^&*())(!"№;%:?*+-=)]/giu
  console.log(re.test(name))
  return re.test(name)
}


</script>

<template>
  <div class="windows-wrapper">
    <div class="header">
      <UserAvatar
        :is-edit="isEdit"
        :image="userFormData.image"
        @update:image="image => userFormData.image = image"
      />
      <div class="header__title">
        {{ userStore.user.firstName }} {{ userStore.user.lastName }}
      </div>
    </div>
    <div class="body">
      <div class="user-information">
        <div class="user-information-block">
          <div class="user-information-block__title">
            <div>
              Email
            </div>
            <div>


              <div class="user-control">
                <div
                    v-if="!isEdit"
                    class="user-control__edit"
                    @click="toggleEdit"
                >
                  <SvgIcon
                      width="14px"
                      height="14px"
                      name="edit"
                  />
                  Редактировать
                </div>
                <div
                    v-else
                    class="user-control-save"
                >
          <span
              class="user-control-save__cancel"
              @click="toggleEdit"
          >Отменить</span>
                  <div
                      class="user-control-save__send"
                      @click="saveUser"
                  >
                    Сохранить изменения
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div
            v-if="!isEdit"
            class="user-information-block__item"
          >
            {{ userStore.user.email }}
          </div>
          <div
            v-else
            class="user-edit-block"
          >
            <TextInput
              :value="userFormData.email"
              place-holder="user@globus.ru"
              :error="errorList.some((error) => error.type === 'email')"
              @update:value="newValue => userFormData.email = newValue"
            />
          </div>
        </div>

        <div class="user-information-block">
          <div class="user-information-block__title">
            Телефон
          </div>
          <div
            v-if="!isEdit"
            class="user-information-block__item"
          >
            {{ userStore.user.phone }}
          </div>
          <div
            v-else
            class="user-edit-block"
          >
            <TextInput
              :value="userFormData.phone"
              :error="errorList.some((error) => error.type === 'phone')"
              :error-message="errorList.find((error) => error.type === 'phone')?.message"
              place-holder="+7 (800) 555-35-35"
              :mask="`+7 (###) ###-##-##`"
              type="tel"
              @update:value="newValue => userFormData.phone = newValue"
            />
          </div>
        </div>

        <div class="user-information-block">
          <div class="user-information-block__title">
            ФИО
          </div>

          <div
            v-if="!isEdit"
            class="user-information-block__item"
          >
            {{ userStore.getFullName() }}
          </div>
          <div
            v-else
            class="user-edit-block"
          >
            <TextInput
              title="Фамилия"
              :error="errorList.some((error) => error.type === 'lastName')"
              :error-message="errorList.find((error) => error.type === 'lastName')?.message"
              :value="userFormData.lastName"
              place-holder="Иванов"
              @update:value="newValue => userFormData.lastName = newValue"
            />
            <TextInput
              title="Имя"
              :error="errorList.some((error) => error.type === 'firstName')"
              :error-message="errorList.find((error) => error.type === 'firstName')?.message"
              :value="userFormData.firstName"
              place-holder="Иван"
              @update:value="newValue => userFormData.firstName = newValue"
            />
            <TextInput
              title="Отчество"
              :error="errorList.some((error) => error.type === 'middleName')"
              :error-message="errorList.find((error) => error.type === 'middleName')?.message"
              :value="userFormData.middleName"
              place-holder="Иванович"
              @update:value="newValue => userFormData.middleName = newValue"
            />
          </div>
        </div>
      </div>



    </div>
  </div>
</template>

<style scoped lang="scss">

@import '@/assets/styles/vars.scss';

.windows-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 100px;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 175px;
    height: 20%;

    &__title {
      font-size: 28px;
      font-weight: 400;
    }
  }

  .body {
    height: 80%;
    width: 100%;
    background-color: $light-blue;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    justify-content: space-between;

    .user-control {
      &__edit {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 170px;
        height: 40px;
        background-color: #FFFFFF;
        color: $grey-dark;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        gap: 8px;
      }

      &-save {
        display: flex;
        gap: 10px;

        width: 275px;
        height: 40px;

        &__cancel {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: $globe-active-color;
        }

        &__send {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6.4px 15px 6.4px 15px;
          background: $button-active-color;
          border-radius: 8px;
          color: #FFFFFF;
        }
      }
    }

    .user-information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      &-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;

        &__title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-size: 16px;
        }

        &__item {
          font-weight: 400;
          font-size: 14px;
          color: #898989;
        }
      }
    }

  }
}

.user-edit-block {
  display: flex;
  gap: 8px;
  &__input {
    width: 310px;
    height: 40px;
    border: 1px solid $grey-light;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    gap: 4px;
  }
}

</style>