<script setup>
import {computed, defineProps, ref, defineEmits, onMounted, watch} from 'vue';
import {useUser} from "@/store/user";
import SvgIcon from "@/components/common/svgButton.vue";
import {useProjects} from "@/store/projects";
import {createProjectForm} from "@/composables/constans/formData";
import BlockView from "@/components/common/BlockView.vue";

onMounted(async () => {
  await userStore.getUser().then(() => {
    createProjectFormData.value.users.push(userStore.user?.userId)
  })
  await userStore.getOrganizationMembers()
  await projectStore.getCountries()
})

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  project: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['closeModal', 'updateProjectList'])

const availableModuleList = computed(() => {
  return userStore?.user?.modules.filter(item => item.moduleName !== 'Главный')
})

const userStore = useUser()
const projectStore = useProjects()

const showAddMember  = ref(false)
const showCountryList = ref(false)
const errorList = ref([])

const searchCountryInput = ref('')
const selectedUsers = ref([])
const selectedUsersTemp = ref([])
const selectedCountry = ref({
  name: null,
  iso2: null,
  iso3: null
})

const createProjectFormData = ref(JSON.parse(JSON.stringify(createProjectForm)))


/* POST Запрос создания нового проекта **/
const createProject = async () => {
  errorList.value = []
  if (createProjectFormData.value.name.length === 0) {
    errorList.value.push('noName')
  }

  if (createProjectFormData.value.modules.length === 1) {
    errorList.value.push('noModule')
  }

  if (createProjectFormData.value.countryId === null) {
    errorList.value.push('NoCountry')
  }

  createProjectFormData.value.users = selectedUsers.value

  if (errorList.value.length > 0) {
    console.log(errorList)
  } else {
    if (!props.isEdit) {
      await projectStore.createProject(createProjectFormData.value)
    } else {
      await projectStore.updateProject(createProjectFormData.value)
    }

    emit('closeModal')
    emit('updateProjectList')
  }
}

/* Добавление модуля в список **/
const toggleModule = (module) => {
  const findModule = createProjectFormData.value.modules.filter((item) => item === module)
  if (findModule.length > 0) {
    createProjectFormData.value.modules = createProjectFormData.value.modules.filter((item) => item !== module)
  } else {
    createProjectFormData.value.modules.push(module)
  }
}

/* Проверка добавлен ли модуль для css класса  **/
const isActiveModule = (module) => {
  const findModule = createProjectFormData.value.modules.filter((item) => item === module)
  return findModule.length > 0;
}

/* Проверка добавлена ли персона для css класса  **/
const isActiveUser = (user) => {
  const findModule = selectedUsers.value.filter((item) => item === user)
  return findModule.length > 0;
}

watch(() => props.show, (status) => {
  if (status === false) {
    createProjectFormData.value = JSON.parse(JSON.stringify(createProjectForm))
    selectedCountry.value.name = null
  } else {
    if (props.isEdit) {
      createProjectFormData.value = props.project
      selectedUsers.value = props.project.users

      console.log(selectedUsers)

      projectStore.getCountry(props.project.countryId).then(() => {
        selectedCountry.value = projectStore.foundedCountryId
      })
    }
  }
})

const closeSelectedUsers = () => {
  showAddMember.value = false
  selectedUsers.value = selectedUsersTemp.value
}

const saveSelectedUsers = () => {
  showAddMember.value = false
  selectedUsersTemp.value = selectedUsers.value
}


</script>

<template>
  <div
    v-if="props.show"
    class="modal-wrapper"
  >
    <div
      class="modal-create-project"
    >
      <div class="project-header">
        <div
          v-if="!isEdit"
          class="project-header__title"
        >
          Создать новый проект
        </div>
        <div
          v-else
          class="project-header__title"
        >
          Настройки проекта
        </div>

        <div

          class="project-header__close"
          @click="emit('closeModal')"
        >
          <SvgIcon
            name="x"
            width="20px"
            height="20px"
          />
        </div>
      </div>
      <div class="project-name">
        <input
          v-model="createProjectFormData.name"
          class="project-name__input"
          :class="{'input-error': errorList.filter((i) => i === 'noName').length > 0}"
          type="text"
          placeholder="Название проекта"
        >
        <small
          v-if="errorList.filter((i) => i === 'noName').length > 0"
          class="text-error"
        >Название проекта не может быть пустым</small>
      </div>
      <div class="project-description">
        <textarea
          v-model="createProjectFormData.description"
          class="project-description__input"
          placeholder="Добавить описание"
        />
      </div>

      <div>
        <div class="project-module-list">
          <div
            v-for="module in availableModuleList"
            :key="module"
            class="project-module-list__item"
            :class="{'project-module-list__item-active': isActiveModule(module?.moduleId)}"
            @click="toggleModule(module?.moduleId)"
          >
            <SvgIcon
              :name="module?.moduleId"
              width="21px"
              height="21px"
            />
            {{ module?.moduleName }}
          </div>
        </div>
        <small
          v-if="errorList.filter((i) => i === 'noModule').length > 0"
          class="text-error"
        >Необходимо выбрать как минимум 1 модуль</small>
      </div>

      <div class="project-country">
        <div class="country-side">
          <div
            class="country-side__input"
          >
            <input
              v-model="searchCountryInput"
              placeholder="Страна"
              type="text"
              @focusin="showCountryList = true"
            >
            <div class="arrow">
              <SvgIcon
                width="15px"
                height="15px"
                :name="`arrow`"
              />
            </div>
          </div>
          <div
            v-if="showCountryList"
            class="country-side-list scroll"
          >
            <div
              v-for="country in projectStore.countries.filter((item) => item.name?.toLowerCase().includes(searchCountryInput.toLowerCase()))"
              :key="country"
              class="country-side-list__item"
              @click="selectedCountry = country; createProjectFormData.countryId = country.iso2; showCountryList = false"
            >
              {{ country.name }}
            </div>
          </div>
          <div
            v-if="selectedCountry.name"
            class="country-side__item"
          >
            {{ selectedCountry.name }}
          </div>
          <small
            v-if="errorList.filter((i) => i === 'NoCountry').length > 0"
            class="text-error"
          >Укажите страну и регион проекта</small>
        </div>
        <div class="region-side">
          <div class="region-side__input">
            <input
              placeholder="Регион"
              disabled
              type="text"
            >
            <div class="arrow">
              <SvgIcon
                width="15px"
                height="15px"
                :name="`arrow`"
              />
            </div>
          </div>
          <div class="region-side__item" />
        </div>
      </div>

      <div class="project-control">
        <div
          class="project-control__add-member"
        >
          <div
            v-if="showAddMember"
            class="member-wrapper"
          >
            <div
              class="member-list scroll"
            >
              <div
                v-for="member in userStore.organizationMembers"
                :key="member"
                class="member-list-item"
                :class="{'member-list-item_active': isActiveUser(member.userId)}"
              >
                <input
                  v-model="selectedUsers"
                  class="member-list-item__checkbox"
                  type="checkbox"
                  :value="member.userId"
                >
                <img
                  v-if="member.image"
                  class="member-list-item__avatar"
                  :src="member.image"
                  alt=" "
                  onerror="this.src = 'http://192.168.0.34:8081/no-avatar.png'"
                >
                <img
                  v-else
                  class="member-list-item__avatar"
                  src="http://192.168.0.34:8081/no-avatar.png"
                  alt=" "
                >
                <div
                  class="member-list-item__name"
                >
                  <span v-if="member.firstName || member.lastName">
                    {{ member.firstName || member.lastName }}
                  </span>
                  <span v-else>
                    {{ member.userId }}
                  </span>
                </div>
              </div>
            </div>

            <div
              class="member-list__add"
              @click="saveSelectedUsers"
            >
              Сохранить изменения
            </div>
          </div>

          <div @click="showAddMember ? closeSelectedUsers() : showAddMember = true;">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3334 14V12.6667C13.3334 11.9594 13.0524 11.2811 12.5523 10.781C12.0522 10.281 11.3739 10 10.6667 10H5.33335C4.62611 10 3.94783 10.281 3.44774 10.781C2.94764 11.2811 2.66669 11.9594 2.66669 12.6667V14"
                stroke="#373D3F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99998 7.33333C9.47274 7.33333 10.6666 6.13943 10.6666 4.66667C10.6666 3.19391 9.47274 2 7.99998 2C6.52722 2 5.33331 3.19391 5.33331 4.66667C5.33331 6.13943 6.52722 7.33333 7.99998 7.33333Z"
                stroke="#373D3F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span>Добавить участников</span>
          </div>
        </div>
        <div
          class="project-control__create"
          @click="createProject"
        >
          <span v-if="!isEdit">Создать</span>
          <span v-else>Изменить</span>
        </div>
      </div>
    </div>
    <BlockView
      v-if="showAddMember || showCountryList"
      @click="closeSelectedUsers(); showCountryList = false"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';
@import '@/assets/styles/classes.scss';

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal-create-project {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 30px;


    width: 555px;
    min-height: 395px;
    background: #FFFFFF;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 0 50vmax rgba(0,0,0,.5);

    .project-header {
      display: flex;
      justify-content: space-between;
      &__title {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: $black;
      }

      &__close {
        cursor: pointer;
      }
    }

    .project-name {
      .project-name__input {
        width: 100%;
        height: 40px;
        padding: 8px 12px 8px 12px;
        border: 1px solid $grey-light;
        border-radius: 8px;
      }
    }

    .project-description {
      .project-description__input {
        resize: none;
        width: 100%;
        height: 90px;
        border-radius: 8px;
        padding: 8px 12px 8px 12px;
        background: $light-blue;
      }
    }

    .project-module-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
      &__item {
        user-select: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 35px;
        border-radius: 22px;
        gap: 5px;
        padding: 4px 12px 4px 12px;
        border: 1px solid $grey-light;
        color: $blue;

        &-active {
          background: $light-gray;
        }
      }
    }

    .project-country {
      display: flex;
      justify-content: space-between;
      .country-side {
        display: flex;
        flex-direction: column;
        gap: 10px;
        z-index: 2001;

        &__item {
          display: flex;
          cursor: pointer;
          align-items: center;
          width: 100%;
          height: 40px;
          color: $blue;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 400;
          padding: 10px 14px 10px 14px;
          &:hover {
            background: $hover-active-color;
          }
        }

        &-list {
          position: absolute;
          transform: translateY(-185px);
          width: 230px;
          height: 180px;
          display: flex;
          background: #FFFFFF;
          box-shadow: 1px 2px 10px 0 #131E2726;
          z-index: 2;
          flex-direction: column;
          padding: 6px;
          gap: 4px;
          border-radius: 8px;

          overflow-y: scroll;

          &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 100%;
            min-height: 40px;
            color: black;

            &:hover {
              background-color: $hover-active-color;
            }
          }
        }

        &__input {
          display: flex;
          height: 40px;

          input {
            width: 180px;
            height: 100%;
            border-left: 1px solid $globe-passive-color;
            border-top: 1px solid $globe-passive-color;
            border-bottom: 1px solid $globe-passive-color;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            padding: 10px;
          }

          .arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            height: 100%;
            width: 50px;
            border-right: 1px solid $globe-passive-color;
            border-top: 1px solid $globe-passive-color;
            border-bottom: 1px solid $globe-passive-color;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .region-side {
        display: flex;
        flex-direction: column;

        &__input {
          display: flex;
          height: 40px;

          input {
            width: 180px;
            height: 100%;
            border-left: 1px solid $globe-passive-color;
            border-top: 1px solid $globe-passive-color;
            border-bottom: 1px solid $globe-passive-color;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            padding: 10px;
          }

          .arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            height: 100%;
            width: 50px;
            border-right: 1px solid $globe-passive-color;
            border-top: 1px solid $globe-passive-color;
            border-bottom: 1px solid $globe-passive-color;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }

    .project-control {
      display: flex;
      justify-content: space-between;
      z-index: 3000;
      position: relative;
      user-select: none;


      &__add-member {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 40px;
        border: 1px solid $grey-light;
        border-radius: 8px;
        padding: 6px 15px 6px 15px;
        gap: 10px;


        .member-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          position: absolute;
          width: 220px;
          height: 320px;
          background: #FFFFFF;
          border-radius: 8px;
          box-shadow: 1px 2px 10px 0 #131E2726;
          bottom: 45px;
          padding: 6px;
          z-index: 2000;


          .member-list {
            height: 300px;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            gap: 4px;

            &-item {
              display: flex;
              border-radius: 8px;
              gap: 4px;
              padding: 10px 14px 10px 14px;

              &__avatar {
                border-radius: 50%;
                width: 20px;
                height: 20px;
              }

              &__name {
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

              }

              &:hover {
                background: $light-gray;
              }

              &_active {
                background: $light-gray;
              }
            }

            &__add {
              height: 40px;
              width: 100%;
              background: $blue-dark;
              border-radius: 8px;
              color: #FFFFFF;
              display: flex;
              justify-content: center;
              align-items: center;

            }
          }
        }
      }

      &__create {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 40px;
        border-radius: 8px;
        padding: 6px 15px 6px 15px;
        background: $blue-dark;
        color: #FFFFFF;
      }
    }
  }
}



</style>