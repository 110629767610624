<script setup>

import SvgButton from "@/components/common/svgButton.vue";
import {onMounted, ref} from "vue";
import {useUser} from "@/store/user";
import {getModuleUrl} from "@/composables/tools/services.tools";

const tooltip = ref({})
const userStore = useUser()

const currentModule = ref(window.location.href.replace('https://', '').split('.')[0])
// const currentModule = ref('globus')

const goToModule = (module) => {
  window.open(getModuleUrl(module));
}

onMounted(() => {
  console.log(userStore.user.modules)
  console.log(currentModule.value)
})

</script>

<template>
  <div class="services-block">
    <div class="services-block__header">
      Сервисы
    </div>
    <div class="services-block__body">
      <template
        v-for="module in userStore.user.modules"
        :key="module.url"
      >
        <div
          v-if="module.moduleName !== 'Публикатор' && module.moduleName !== 'Главный'"
          class="service-item"
          :class="{'service-item_selected': module.moduleId.split('_')[1] === currentModule}"
          @click="goToModule(module)"
          @mouseenter="tooltip[module.moduleId] = true"
          @mouseleave="tooltip[module.moduleId] = false"
        >
          <SvgButton
            class="service-item__icon"
            width="30px"
            height="30px"
            :name="module.moduleId"
          />
          <div
            v-if="tooltip[module.moduleId]"
            class="service-item__tooltip"
          >
            {{ module.moduleName }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/vars.scss';

.services-block {
  position: absolute;
  top: 6vh;
  right:10px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  width: 240px;

  box-shadow: 2px 4px 20px 0 #00203926;
  background: #FFFFFF;
  border-radius: 8px;

  z-index: 2000;


  &__header {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    color: $grey;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 0 14px 0 14px;
  }
}

.service-item {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px;

  &:hover {
    background: $light-blue;
    border-radius: 8px;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__tooltip {
    position: absolute;
    width: 106px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px 0 #131E2726;
    top: 110%;
  }
}

.service-item_selected {
  border: 1px solid $blue;
  border-radius: 8px;
}

</style>